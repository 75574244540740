import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './core/services/authentication.service';
import { NavbarService } from './shared/navbar/navbar.service';
import { AppLocaleStorageService } from './shared/util/local-storage.service';
import { RequestService } from './core/services/request.service';
import { VersionService } from './shared/version/version.service';
import { IUser } from './shared/auth/user';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import Gleap from '../../node_modules/gleap';

import * as WebFont from 'webfontloader';
import { Url } from './shared/util/url.class';
import { UrlService } from './core/services/url.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <div class="spiner-overlayer" [ngStyle]="{ display: showSpinner ? 'block' : 'none' }"></div>
    <div class="spinner" [ngStyle]="{ display: showSpinner ? 'block' : 'none' }">
      <img width="80%" src="../assets/svg/icon-logo.svg" />
    </div>
  `
})
export class AppComponent implements OnInit {
  title = 'fn-client-webapp';
  showSpinner = false;

  constructor(
    translateService: TranslateService,
    authenticationService: AuthenticationService,
    urlService: UrlService,
    private router: Router,
    requestService: RequestService,
    navbarService: NavbarService,
    alss: AppLocaleStorageService,
    versionService: VersionService
  ) {
    translateService.setDefaultLang('de');

    authenticationService.onAuthenticationSuccess().subscribe((user: IUser) => {
      /** TODO CHANGE TO supplier FROM localhost */
      if (location.host.includes('supplier') && router.url.startsWith('/supplier/login')) {
        router.navigate(['/supplier'], { replaceUrl: true });
      } else {
        if (router.url.startsWith('/login')) {
          router.navigate(['/'], { replaceUrl: true });
        }
        navbarService.loadNavbar();
        if (user.lang !== alss.getItem('language')) {
          translateService.use(user.lang);
        }
      }

      Gleap.identify(user.id.toString(), {
        email: user.email,
        name: user.firstName
      });
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.showSpinner = true;
        if (e.url.includes('erp')) {
          document.documentElement.setAttribute('color-mode', 'dark');
        } else {
          document.documentElement.setAttribute('color-mode', localStorage.getItem('foodnotify.viewMode'));
        }
      }
      if (e instanceof NavigationEnd) {
        this.showSpinner = false;
      }
      if (e instanceof NavigationCancel) {
        this.showSpinner = false;
      }
      if (e instanceof NavigationError) {
        this.showSpinner = false;
      }
    });

    translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      alss.setItem('language', event.lang);

      const user = authenticationService.getUser() as IUser;
      if (user && user.lang !== event.lang) {
        const url: Url = urlService.baseAppUrl;
        url.push('lang');
        url.put('lang', event.lang);

        requestService.get(url.serialize()).subscribe({
          next: () => {
            user.lang = event.lang;
            alss.setItem(`language`, event.lang);
          },
          error: (error) => {
            console.error(error);
          }
        });
      }
    });

    let language = alss.getItem('language');
    if (!language) {
      language = translateService.getBrowserCultureLang().split('-')[0]; // en-US.json
    }

    translateService.use(language);
  }
  ngOnInit(): void {
    Gleap.initialize('luccHMyE9jFDLSEHv9Rp6U23EhkTXIES');

    WebFont.load({
      google: {
        families: [
          'ABeeZee',
          'Aref+Ruqaa',
          'Acme',
          'Allura',
          'Baloo',
          'Belgrano',
          'Butterfly+Kids',
          'Black Han Sans',
          'Cabin',
          'Chango',
          'Courgette',
          'Della+Respira',
          'Dosis',
          'Eater',
          'Exo',
          'Farsan',
          'Fenix',
          'Gabriela',
          'Galindo',
          'Gorditas',
          'Habibi',
          'Harmattan',
          'Jua',
          'Jura',
          'Kosugi',
          'Krub',
          'Lemon',
          'Lora',
          'Mali',
          'Marvel',
          'Muli',
          'Notable',
          'Oswald',
          'Petrona',
          'Pridi',
          'Raleway',
          'Rasa',
          'Scada',
          'Spirax',
          'Ultra',
          'Viga',
          'Yrsa',
          'Walter Turncoat'
        ]
      }
    });
  }
}
